import React from 'react';
import '../css/blog.css';
import blog from '../images/blog.jpg';
import blogimg from '../images/blogimg.jpg';
import author from '../images/author.svg';
import search from '../images/search.png';
import coffee from '../images/coffee.png';


function Blog() {
    return (
        <>
            <div style={{ marginTop: "68px", marginRight: "0", marginLeft: '0' }}>
                <img style={{ width: "98.9vw" }} src={blog} alt="." />
            </div>
            <div className='blogPageWrapper d-flex flex-wrap align-items-center justify-content-between'>
                <div className='blogPage' >
                    <p style={{ fontSize: "24px", fontWeight: "700" }}>Making Connections, One Coffee Date at a Time with Umla
</p>
                    <img className='blogImage' src={coffee} alt="." />
                    <p className='blogText'>At Umla, we believe that the best connections are made over a cup of coffee. That's why we've created a platform that brings people together, one coffee date at a time. Whether you're looking to expand your professional network, meet new friends, or even find your soulmate, Umla is here to help you make meaningful connections over a shared love for coffee.With Umla, scheduling coffee dates has never been easier. Our platform allows you to browse profiles, send invitations, and coordinate meetups seamlessly, ensuring that you can connect with others at a time and place that works for you.Don't miss out on the chance to expand your network and make meaningful connections with Umla. Download today and start scheduling your coffee dates!</p>
                </div>
                <div className='d-flex gap-3 flex-column align-items-start justify-content-start'>
                    <div className='author d-flex gap-3 align-items-center justify-content-start'>
                        <img style={{ height: "80px", width: "70px" }} src={author} alt="." />
                        <div style={{ marginTop: "15px" }}>
                            <p style={{ fontSize: "15px", fontWeight: "600",marginTop:"-10px" }}>Written By</p>
                            <p style={{ color: "#5E5E5E", fontWeight: "600", fontSize: "15px" ,marginTop:"10px"}}>Aabhash Kesharwani</p>
                            <p style={{ color: "#676767", fontSize: "10px",marginTop:"8px" }}>Date:- 07-Jan-2024</p>
                        </div>
                    </div>
                    <div className='suggestions'>
                        <p style={{ fontSize: "14px", fontWeight: "600",marginLeft:"14px" }}>You May Also Like</p>
                        <div className='d-flex flex-column gap-1' style={{ lineHeight: "15px", width: "200px", marginLeft: "15px" }}>
                            <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/6">Date Safer with Umla</a> <br />
                            <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/8">See Who Likes You</a><br />
                            <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/4">Find Your Perfect Match</a><br />
                        </div>
                    </div>
                    {/* <div className='search pb-4 d-flex flex-column'>
                        <img src={search} alt="." />
                        <div>
                           <p style={{fontWeight:"600" ,fontSize:"17px",padding:"7px",textAlign:"center"}}>Choose Type ?</p>
                           <div className='d-flex gap-2 flex-column align-items-center justify-content-center'>
                           <select style={{width:"260px",borderRadius:"8px",border:"none",padding:"4px"}} name="" id=""></select>
                           <button style={{color:"white",background:"#6D12FF",width:"150px",fontSize:"14px",fontWeight:"500",border:"none",borderRadius:"7px",padding:"7px"}}>Search</button>
                           </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Blog