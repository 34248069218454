import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Blogs from "./Components/Blogs";
import Restaurants from "./Components/Restaurants";
import Blog from "./Components/Blog";
import FullPage from "./pages/registrationForm/FullPage";
import Privacy from "./Components/Privacy";
import ContactUs from "./Components/ContactUs";
import Blog2 from "./Components/Blog2";
import Blog3 from "./Components/Blog3";
import Blog4 from "./Components/Blog4";
import Blog5 from "./Components/Blog5";
import Blog6 from "./Components/Blog6";
import Blog7 from "./Components/Blog7";
import Blog8 from "./Components/Blog8";
import { useEffect } from "react";	
import ScrollController from "./ScrollController";

function App() {
	
  return (
    <>
      <BrowserRouter>
	    <ScrollController/>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/1" element={<Blog />} />
          <Route path="/blog/2" element={<Blog2/>} />
          <Route path="/blog/3" element={<Blog3 />} />
          <Route path="/blog/4" element={<Blog4 />} />
          <Route path="/blog/5" element={<Blog5 />} />
          <Route path="/blog/6" element={<Blog6 />} />
          <Route path="/blog/7" element={<Blog7 />} />
          <Route path="/blog/8" element={<Blog8 />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/restaurants" element={<Restaurants />} />
          <Route path="/register" element={<FullPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
