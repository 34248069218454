import { PARTNER_ONBOARD_REQUEST, PARTNER_ONBOARD_SUCCESS, PARTNER_ONBOARD_FAILURE } from "../constants/partnerConstant";


export const onBoardRequestReducer = (state = {}, action) => {

    switch (action.type) {
        case PARTNER_ONBOARD_REQUEST: 
        return {loading: true};

        case PARTNER_ONBOARD_SUCCESS: 
        return {loading: true, request: action.payload.onboardRequest};

        case PARTNER_ONBOARD_FAILURE: 
        return {loading: false, error: action.payload};
        
        default:
            return state;
    }
}