import React from 'react';
import '../css/about.css';
import aboutbackground from '../images/aboutbackground.jpg';
import about1 from '../images/about1.png';
import about2 from '../images/about2.png';
import purpledot from '../images/purpledot.svg';

function About() {
     return (
          <>
               <div className='d-flex justify-content-center align-items-end' style={{ marginTop: "68px", backgroundImage: `url(${aboutbackground})`, backgroundSize: "cover",backgroundPosition:"center" ,backgroundRepeat: "no-repeat",height:"91vh"}}>
                    <div className='aboutOffer'>OFFER <img className='purpledot' src={purpledot} alt="."/> MATCH <img className='purpledot' src={purpledot} alt="."/> MEET</div>
               </div>
               <div className='mt-5 d-flex flex-wrap align-items-center justify-content-between'>
                    <div>
                         <img className='about1Img' src={about1} alt="." />
                    </div>
                    <div className='text1'>
                         <p style={{ fontSize: "32px", color: "#6D12FF", fontWeight: "700" }}>The Ultimate Networking App UMLA.</p>
                         <p style={{ fontSize: "22px", color: "#434343", fontWeight: "500" }}>Welcome to UMLA, the premier networking app that connects people with similar interests and promotes community building. Our mission is to make it easier than ever for individuals to connect with others who share their passions and hobbies, fostering friendships, collaborations, and opportunities.</p>
                    </div>
               </div>
               <div className='mt-5 d-flex flex-wrap align-items-center justify-content-between'>
                    <div className='text2'>
                         <p style={{ fontSize: "32px", color: "#6D12FF", fontWeight: "700" }}>Meet Your Match with UMLA.</p>
                         <p style={{ fontSize: "22px", color: "#434343", fontWeight: "500" }}>At UMLA, we believe that life is all about building connections and forming meaningful relationships. Whether you're looking for new friends to hang out with, seeking a mentor in your field, or hoping to build a professional network, our app provides the tools you need to make those connections.</p>
                    </div>
                    <div className='about2ImgParent'>
                         <img className='about2Img' src={about2} alt="." />
                    </div>
               </div>
          </>
     )
}

export default About