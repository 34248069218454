import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOnboardRequest } from "../../redux/actions/partnerActions";

const steps = ["Profile Details", "Upload Picture", "Review Application"];

export const ProfileDetails = ({ updateData, onchange, bodyStep, data }) => {
  const [profileDetails, setProfileDetails] = useState(data);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentStep, setCurrentStep] = useState(bodyStep);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails({ ...profileDetails, [name]: value });
  };

  useEffect(() => {
    updateData(profileDetails);
  }, [profileDetails]);

  const categories = ["Bar", "Restaurant", "Cafe", "Pub"];

  const handleCategorySelect = (e, category) => {
    e.preventDefault();
    setSelectedCategory(category);
    setProfileDetails({ ...profileDetails, category });
  };

  const handleClick = (direction) => {
    if (direction === "next" && !validateInputs()) {
      return;
    }

    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
    onchange(newStep);
    updateData(profileDetails);
  };

  const validateInputs = () => {
    const errors = {};

    if (!profileDetails.companyName) {
      errors.companyName = "Restaurant Name is required";
    }
    if (!profileDetails.executiveName) {
      errors.executiveName = "Manager Name is required";
    }
    if (!profileDetails.contactNumber) {
      errors.contactNumber = "Manager Contact Number is required";
    }
    if (!profileDetails.email) {
      errors.email = "Email Address is required";
    }
    if (!profileDetails.city) {
      errors.city = "City is required";
    }
    if (!profileDetails.openAt || !profileDetails.closeAt) {
      errors.workingHours = "Working Hours are required";
    }
    if (!profileDetails.address) {
      errors.address = "Address is required";
    }

    setErrors(errors);

    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };

  return (
    <div className=" p-0 ">
      <div className="m-2">
        <h1 className=" text-2xl m-2">Enter Company's Profile Details</h1>
      </div>

      <form
        className="border-2 border-[#646464] border-opacity-10 m-2 p-3 rounded-2xl h-auto shadow-sm "
        method="post"
      >
        <div className=" ">
          <span className=" text-[#252525] font-medium">Category</span>
          <div className=" flex flex-row-4 gap-28 py-2">
            {categories.map((category) => (
              <button
                key={category}
                onClick={(e) => handleCategorySelect(e, category)}
                className={`py-1 px-8 rounded-full border-2 border-[#646464] border-opacity-10 ${
                  selectedCategory === category ? "bg-[#515ADA] text-white" : ""
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
        <div className="">
          <label className="text-[#252525] font-medium">Restaurant Name</label>
          <input
            onChange={handleChange}
            value={profileDetails["companyName"]}
            name="companyName"
            type="text"
            placeholder="Townhouse Coffee"
            className=" p-3 my-2 w-full rounded-lg border-2 border-[#646464]  border-opacity-10 h-8"
            required
          />
          {errors.companyName && (
            <p className="text-red-500 text-xs mb-1">{errors.companyName}</p>
          )}
          <div>
            <label className="text-[#252525]  font-medium">Manager Name</label>
            <input
              onChange={handleChange}
              value={profileDetails["executiveName"]}
              name="executiveName"
              type="text"
              placeholder="Shyam Lal"
              className=" p-3 my-2 w-full rounded-lg border-2 border-[#646464] border-opacity-10 h-8"
              required
            />
            {errors.executiveName && (
              <p className="text-red-500 text-xs mb-1">
                {errors.executiveName}
              </p>
            )}
          </div>
          <div className=" grid grid-cols-2 gap-4 ">
            <div className="grid grid-cols-1 text-[#252525] font-medium ">
              <label className=" "> </label>
              Manager Contact Number
              <input
                onChange={handleChange}
                value={profileDetails["contactNumber"]}
                name="contactNumber"
                type="tel"
                placeholder="1234567890"
                className=" p-3 my-2 rounded-lg border-2 border-[#646464] border-opacity-10 h-8"
                required
              />
              {errors.contactNumber && (
                <p className="text-red-500 text-xs mb-1">
                  {errors.contactNumber}
                </p>
              )}
            </div>

            <div className="grid grid-cols-1 text-[#252525] mt-2 font-medium">
              <label className=""> </label>
              Email Address
              <input
                onChange={handleChange}
                value={profileDetails["email"]}
                name="email"
                type="email"
                placeholder="abc@gmail.com"
                className=" p-4 my-2  rounded-lg border-2 border-[#646464] border-opacity-10 h-8"
                required
              />
              {errors.email && (
                <p className="text-red-500 text-xs mb-1">{errors.email}</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-[#252525] mt-2 font-medium">City</p>
              <input
                onChange={handleChange}
                value={profileDetails["city"]}
                name="city"
                type="text"
                placeholder="Jaipur"
                className="p-4 my-2 w-full rounded-lg border-2 border-[#646464] border-opacity-10 h-8 "
                required
              />
              {errors.city && (
                <p className="text-red-500 text-xs mb-1">{errors.city}</p>
              )}
            </div>

            {/* <div className=" flex items-center"> */}
            <div>
              <p className="text-[#252525] mt-2 font-medium">Working Hours</p>

              <div className=" flex items-center gap-4">
                <div>
                  <input
                    onChange={handleChange}
                    value={profileDetails["openAt"]}
                    name="openAt"
                    type="time"
                    placeholder="00:00 AM"
                    className="p-4 my-2 w-full rounded-lg border-2 border-[#646464] border-opacity-10 h-8"
                    required
                  />
                </div>
                <div className=" ">To</div>
                <div>
                  <input
                    onChange={handleChange}
                    value={profileDetails["closeAt"]}
                    name="closeAt"
                    type="time"
                    placeholder="00:00 PM"
                    className="p-4 my-2 w-auto rounded-lg border-2 border-[#646464] border-opacity-10 h-8"
                    required
                  />
                </div>
              </div>
              {errors.workingHours && (
                <p className="text-red-500 text-xs mb-1">
                  {errors.workingHours}
                </p>
              )}
            </div>
          </div>

          <div>
            <label className="text-[#252525] mt-2 font-medium">Address</label>
            <input
              onChange={handleChange}
              value={profileDetails["address"]}
              name="address"
              type="text"
              placeholder="A 91, Sarthi Marg, Vaishali Nagar, Jaipur, Rajasthan 302021"
              className=" p-8 my-2 w-full rounded-lg border-2 border-[#646464] border-opacity-10 h-8"
              required
            />
            {errors.address && (
              <p className="text-red-500 text-xs mb-1">{errors.address}</p>
            )}
          </div>
        </div>
      </form>

      <div className=" m-2 flex justify-between my-4">
        <p className="">*All details are mandatory</p>

        <div className=" space-x-8">
          <button
            onClick={() => handleClick("next")}
            className="border-2 border-[#515ADA] bg-[#515ADA] text-white py-2 px-4 rounded-lg cursor-pointer hover:opacity-90 transition duration-200 ease-in-out"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export const UploadPicture = ({ updateData, onchange, bodyStep }) => {
  const [picture, setPicture] = useState({
    images: [],
  });
  const [currentStep, setCurrentStep] = useState(bodyStep);

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
    onchange(newStep);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const imagesArray = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.type.startsWith("image/")) {
        imagesArray.push(file);
      }
    }

    setPicture({ ...picture, images: [...picture.images, ...imagesArray] });
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files).filter((file) =>
      file.type.startsWith("image/")
    );

    setPicture({ ...picture, images: [...picture.images, ...imagesArray] });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    updateData(picture);
  }, [picture]);

  return (
    <div className="flex justify-center">
      <div className="m-4 " onDrop={handleDrop} onDragOver={handleDragOver}>
        <form
          method="post"
          className="border border-gray-200 mt-10 p-6 rounded-lg shadow-sm h-auto text-center "
        >
          <h1 className=" text-3xl">Upload Your Images</h1>
          <p className=" my-4 text-gray-400"> Images should be an jpg/png </p>
          <div className=" border-dashed border-2 border-sky-200 rounded-lg text-lg">
            <h2 className=" font-bold text-xl my-2">Drop files here</h2>
            or
            <div className="my-4">
              <div>
                {picture.images && picture.images.length > 0
                  ? picture.images.length + " files selected"
                  : ""}
              </div>
            </div>
            <input
              className=" relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[10px] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-blue-500 dark:file:text-neutral-100 dark:focus:border-primary"
              type="file"
              onChange={handleImageUpload}
              id="formFileMultiple"
              multiple
            />
          </div>
        </form>
        <div className=" m-4 flex justify-between">
          <div className=" space-x-8">
            <button
              onClick={() => handleClick("back")}
              className={` border-2 border-[#515ADA] py-2 px-4 rounded-lg cursor-pointer hover:opacity-70 transition 
      duration-200 ease-in-out`}
            >
              Back
            </button>

            <button
              onClick={() => handleClick("next")}
              className=" bg-[#515ADA] text-white py-2 px-4 rounded-lg cursor-pointer hover:opacity-90 transition duration-200 ease-in-out"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ReviewApplication = ({ allDetails, onchange, bodyStep }) => {
  const [currentStep, setCurrentStep] = useState(bodyStep);
  const [showThankYou, setShowThankYou] = useState(false);

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
    onchange(newStep);
  };

  const dispatch = useDispatch();

  const response = useSelector((state) => state.onBoardRequest);
  const { loading, request, error } = response;

  // console.log(loading);
  console.log(request);
  // console.log(error);

  const handleSubmit = () => {
    const formData = new FormData();

    Object.keys(allDetails).map((key) => {
      if (Array.isArray(allDetails[key])) {
        allDetails[key].map((item, index) => formData.append(key, item));
      } else {
        formData.append(key, allDetails[key]);
      }
    });

    console.log(Array.from(formData));

    setShowThankYou(true);
    dispatch(createOnboardRequest(formData));
  };

  return (
    <div className=" mx-14 max-w-md ">
      <h1 className=" text-2xl m-4">Review Your Application</h1>

      <form
        method="post"
        className="border border-gray-200 m-4 p-6 w-full rounded-lg shadow-sm h-auto"
      >
        <h2 className="text-xl font-semibold mb-2">Profile Details:</h2>
        <div>
          <p className="text-[#646464] my-1">
            Category:{" "}
            <span className=" text-[#252525]">{allDetails.category}</span>
          </p>
          <p className="text-[#646464] my-1">
            Company Name:{" "}
            <span className=" text-[#252525]">{allDetails.companyName}</span>
          </p>
          <p className="text-[#646464] my-1">
            Executive Name:{" "}
            <span className=" text-[#252525]">{allDetails.executiveName}</span>
          </p>
          <p className="text-[#646464] my-1">
            Contact Number:{" "}
            <span className=" text-[#252525]">{allDetails.mobile}</span>
          </p>
          <p className="text-[#646464] my-1">
            City: <span className=" text-[#252525]">{allDetails.city} </span>
          </p>
          <p className="text-[#646464] my-1">
            Working Hours:
            <span className=" text-[#252525]">
              {allDetails.workingFrom} to {allDetails.workingTo}{" "}
            </span>
          </p>
          <p className="text-[#646464]">
            Address:{" "}
            <span className=" text-[#252525]">{allDetails.address}</span>
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold mt-4">Images:</h2>
        </div>

        {allDetails.images && allDetails.images.length > 0 && (
          <div className="my-2">
            <div className="grid grid-cols-3 gap-2">
              {allDetails.images.map((image, index) => (
                <div key={index}>
                  <img src={URL.createObjectURL(image)} alt="" />
                </div>
              ))}
            </div>
          </div>
        )}
      </form>

      {showThankYou ? (
        <div className="text-center text-2xl my-20">
          Thank you, your response has been recorded.
        </div>
      ) : (
        <div className=" m-4 flex justify-between">
          <div className=" space-x-8">
            <button
              onClick={() => handleClick("back")}
              className={`border-2 border-[#515ADA] py-2 px-4 rounded-lg cursor-pointer hover:opacity-70 transition 
      duration-200 ease-in-out`}
            >
              Back
            </button>

            <button
              onClick={handleSubmit}
              className=" bg-[#515ADA] text-white py-2 px-4 rounded-lg cursor-pointer hover:opacity-90 transition duration-200 ease-in-out"
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Body = ({ bodyStep, onchange }) => {
  const [allDetails, setAlldetails] = useState({
    category: "",
    companyName: "",
    executiveName: "",
    contactNumber: "",
    email: "",
    city: "",
    openAt: "",
    closeAt: "",
    address: "",
    images: [],
  });

  const updateData = (newData) => {
    setAlldetails({
      ...allDetails,
      ...newData,
    });
  };

  const displayStep = (step, onchange, data) => {
    switch (step) {
      case 1:
        return (
          <ProfileDetails
            updateData={updateData}
            onchange={onchange}
            bodyStep={bodyStep}
            data={data}
          />
        );

      case 2:
        return (
          <UploadPicture
            updateData={updateData}
            onchange={onchange}
            bodyStep={bodyStep}
          />
        );

      case 3:
        return (
          <ReviewApplication
            allDetails={allDetails}
            onchange={onchange}
            bodyStep={bodyStep}
          />
        );

      default:
    }
  };

  return (
    <div className=" mx-10">{displayStep(bodyStep, onchange, allDetails)}</div>
  );
};

export default Body;
