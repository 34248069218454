import React from 'react'
import '../css/blog.css';
import blog from '../images/blog.jpg';
import blogimg from '../images/blogimg.jpg';
import author from '../images/author.svg';
import search from '../images/search.png';
import hub from '../images/hubread.png';

function Blog7() {
  return (
    <>
    <div style={{ marginTop: "68px", marginRight: "0", marginLeft: '0' }}>
        <img style={{ width: "98.9vw" }} src={blog} alt="." />
    </div>
    <div className='blogPageWrapper d-flex flex-wrap align-items-center justify-content-between'>
        <div className='blogPage' >
            <p style={{ fontSize: "24px", fontWeight: "700" }}>Your Personalized Networking Hub"
</p>
            <img className='blogImage' src={hub} alt="." />
            <p className='blogText'>Are you ready to take your networking experience to the next level? Look no further than Umla – the ultimate destination for professionals, enthusiasts, and innovators looking to forge meaningful connections and unlock new opportunities. With Umla, networking isn't limited to specific times or places. Whether you're commuting to work, taking a coffee break, or winding down for the evening, you can browse profiles and connect with potential matches at your convenience.Don't miss out on the opportunity to elevate your networking experience. download Umla today and join a community of passionate individuals dedicated to forging meaningful connections and driving positive change.</p>
        </div>
        <div className='d-flex gap-3 flex-column align-items-start justify-content-start'>
            <div className='author d-flex gap-3 align-items-center justify-content-start'>
                <img style={{ height: "80px", width: "70px" }} src={author} alt="." />
                <div style={{ marginTop: "15px" }}>
                    <p style={{ fontSize: "15px", fontWeight: "600",marginTop:"-10px" }}>Written By</p>
                    <p style={{ color: "#5E5E5E", fontWeight: "600", fontSize: "15px" ,marginTop:"10px"}}>Mayank Agarwal</p>
                    <p style={{ color: "#676767", fontSize: "10px",marginTop:"8px" }}>Date:- 24-March-2024</p>
                </div>
            </div>
            <div className='suggestions'>
                <p style={{ fontSize: "14px", fontWeight: "600",marginLeft:"14px" }}>You May Also Like</p>
                <div className='d-flex flex-column gap-1' style={{ lineHeight: "15px", width: "200px", marginLeft: "15px" }}>
                    <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/3">Your Gateway to Meaningful Networking</a> <br />
                    <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/1">Making Connections, One Coffee Date at a Time with Umla</a><br />
                    <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/5">Explore Your Passion with Umla: Connect, Collaborate, Create</a><br />
                </div>
            </div>
            {/* <div className='search pb-4 d-flex flex-column'>
                <img src={search} alt="." />
                <div>
                   <p style={{fontWeight:"600" ,fontSize:"17px",padding:"7px",textAlign:"center"}}>Choose Type ?</p>
                   <div className='d-flex gap-2 flex-column align-items-center justify-content-center'>
                   <select style={{width:"260px",borderRadius:"8px",border:"none",padding:"4px"}} name="" id=""></select>
                   <button style={{color:"white",background:"#6D12FF",width:"150px",fontSize:"14px",fontWeight:"500",border:"none",borderRadius:"7px",padding:"7px"}}>Search</button>
                   </div>
                </div>
            </div> */}
        </div>
    </div>
</>
  )
}

export default Blog7;
