import React from 'react';
import '../css/blog.css';
import blog from '../images/blog.jpg';
import blogimg from '../images/blogimg.jpg';
import author from '../images/author.svg';
import search from '../images/search.png';
import whosee from '../images/whoread.png';


function Blog8() {
    return (
        <>
            <div style={{ marginTop: "68px", marginRight: "0", marginLeft: '0' }}>
                <img style={{ width: "98.9vw" }} src={blog} alt="." />
            </div>
            <div className='blogPageWrapper d-flex flex-wrap align-items-center justify-content-between'>
                <div className='blogPage' >
                    <p style={{ fontSize: "24px", fontWeight: "700" }}>See Who Likes You"
</p>
                    <img style={{width:"400px",height:"400px"}} className='blogImage' src={whosee} alt="." />
                    <p className='blogText'>Ever wondered who's interested in connecting with you on Umla? With our "See Who Likes You" feature, you can unlock a world of potential connections and take the first step towards building meaningful relationships with like-minded individuals.Ready to see who likes you and unlock a world of potential connections on Umla? download now and take advantage of this exciting feature to discover mutual matches, initiate conversations, and build meaningful relationships with individuals who share your interests and values.</p>
                </div>
                <div className='d-flex gap-3 flex-column align-items-start justify-content-start'>
                    <div className='author d-flex gap-3 align-items-center justify-content-start'>
                        <img style={{ height: "80px", width: "70px" }} src={author} alt="." />
                        <div style={{ marginTop: "15px" }}>
                            <p style={{ fontSize: "15px", fontWeight: "600",marginTop:"-10px" }}>Written By</p>
                            <p style={{ color: "#5E5E5E", fontWeight: "600", fontSize: "15px" ,marginTop:"10px"}}>Somya Mathur</p>
                            <p style={{ color: "#676767", fontSize: "10px",marginTop:"8px" }}>Date:- 07-Apr-2024</p>
                        </div>
                    </div>
                    <div className='suggestions'>
                        <p style={{ fontSize: "14px", fontWeight: "600",marginLeft:"14px" }}>You May Also Like</p>
                        <div className='d-flex flex-column gap-1' style={{ lineHeight: "15px", width: "200px", marginLeft: "15px" }}>
                            <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/6">Date Safer with Umla</a> <br />
                            <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/4">Find Your Perfect Match, Anytime</a><br />
                            <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/2">Connect, Explore, Share Adventures
</a><br />
                        </div>
                    </div>
                    {/* <div className='search pb-4 d-flex flex-column'>
                        <img src={search} alt="." />
                        <div>
                           <p style={{fontWeight:"600" ,fontSize:"17px",padding:"7px",textAlign:"center"}}>Choose Type ?</p>
                           <div className='d-flex gap-2 flex-column align-items-center justify-content-center'>
                           <select style={{width:"260px",borderRadius:"8px",border:"none",padding:"4px"}} name="" id=""></select>
                           <button style={{color:"white",background:"#6D12FF",width:"150px",fontSize:"14px",fontWeight:"500",border:"none",borderRadius:"7px",padding:"7px"}}>Search</button>
                           </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Blog8;