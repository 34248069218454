import { PARTNER_ONBOARD_REQUEST, PARTNER_ONBOARD_SUCCESS, PARTNER_ONBOARD_FAILURE } from "../constants/partnerConstant";
import axios from "axios";

const API_KEY = "http://4.246.192.231"

export const createOnboardRequest = (bodyData) => async (dispatch) => {
    try{
        dispatch({
            type: PARTNER_ONBOARD_REQUEST,
        });

        const {data} = await axios.post(`${API_KEY}/api/v1/umla/admin/createOnboardRequest`, bodyData);

        dispatch({
            type: PARTNER_ONBOARD_SUCCESS,
            payload: data,
        })
    }
    catch(err){
        console.error(err);
        dispatch({
            type: PARTNER_ONBOARD_FAILURE,
            payload: err.message,
          });
    }
}