import React, { useEffect, useRef, useState } from "react";

const steps = ["Profile Details", "Upload Picture", "Review Application"];

const Sidebar = ({ bodyStep }) => {
  const [newStep, setNewstep] = useState([]);
  const stepRef = useRef();

  const updateStep = (stepNumber, steps) => {
    const newSteps = [...steps];
    let count = 0;

    while (count < newSteps.length) {
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: false,
        };
        count++;
      } else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      } else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }
    return newSteps;
  };

  useEffect(() => {
    const stepsState = steps.map((value, index) =>
      Object.assign(
        {},
        {
          value,
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      )
    );

    stepRef.current = stepsState;
    const current = updateStep(bodyStep - 1, stepRef.current);
    setNewstep(current);
  }, [bodyStep]);

  const displaySteps = newStep.map((step, index) => {
    return (
      <div
        key={index}
        className={
          index !== newStep.length - 1
            ? "w-full flex items-center"
            : "flex items-center"
        }
      >
        <div
          className={`rounded-full transition duration-500 ease-in-out  h-10 w-10 flex items-center justify-center py-3 ${
            step.selected
              ? "  bg-[#515ADA] text-white"
              : "border-2 border-[#E0E2E7]"
          }`}
        >
          {/* {Display number} */}

          <span className="text-xl">{index + 1} </span>
        </div>

        <div
          className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
            step.completed ? "border-[#515ADA]" : "border-gray-300"
          }`}
        >
          {/* {Display line} */}
        </div>
      </div>
    );
  });

  const displayStepperDetails = newStep.map((step, index) => {
    return (
      <div key={index}>
        <button
          className={` p-4 my-1 rounded-lg border-2 border-[#646464] border-opacity-30  w-full  text-left ${
            index + 1 === bodyStep ? "bg-[#515ADA] text-white" : ""
          } ${step.completed ? "bg-[#38A774] text-white" : ""} `}
        >
          <span
            className={`border-2 border-[#515ADA] text-[#515ADA] rounded-full m-2 px-[10px] py-[5px] font-medium  mx-4  ${
              index + 1 === bodyStep ? "border-2 border-white text-white" : ""
            } ${step.completed ? "bg-[#38A774] text-white border-white" : ""}`}
          >
            {index + 1}
          </span>
          {step.value}
        </button>
      </div>
    );
  });

  return (
    <div className="md: w-[400px] mx-auto rounded-2xl p-2 bg-white ">
      <div className=" container horizontal mt-5">
        <div className=" flex justify-between items-center ">
          {displaySteps}
        </div>

        <div className="my-8 w-full ">{displayStepperDetails}</div>
      </div>
    </div>
  );
};

export default Sidebar;
