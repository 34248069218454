import React, { useState } from "react";
import "../css/home.css";
import { Link } from "react-router-dom";
import hero1 from "../images/hero1.png";
import hero2 from "../images/hero2.png";
import hero3 from "../images/hero3.jpg";
import hero4 from "../images/hero4.jpg";
import hero5 from "../images/hero5.jpg";
import hero6 from "../images/hero6.jpg";
import mockup1 from "../images/mockup1.png";
import mockup2 from "../images/mockup2.png";
import mockup3 from "../images/mockup3.png";
import mockup4 from "../images/mockup4.png";
import mockup5 from "../images/mockup5.png";
import download1 from "../images/download1.svg";
import download2 from "../images/download2.svg";
import restaurant from "../images/restaurant.jpg";
import blog1 from "../images/blog1.jpg";
import blog2 from "../images/blog2.jpg";
import blog3 from "../images/blog3.jpg";
import FullPage from "../pages/registrationForm/FullPage";
import eldorado from "../images/eldorado.jpg";
import bonkey from "../images/bonkey.jpg";
import coffeeCartel from "../images/coffeeCartel.jpeg";
import drunkenMonkey from "../images/drunkenMonkey.png";
import elixir from "../images/elixir.jpg";
import theFarm from "../images/theFarm.jpeg";
import Marquee from "react-fast-marquee";
import connectread from '../images/connectread.png'
import coffee from '../images/coffee.png'
import network from '../images/networkread.png'

function Home() {
  const [isRegister, setisRegister] = useState(false);

  const handleClick = () => {
    setisRegister(true);
  };

  const handleCloseRegister = () => {
    setisRegister(false);
  };

  return (
    <>
    <div className="home-container">
      <div
      style={ {marginTop: "12vh" } }
        className="hero d-flex justify-content-between align-items-center"
      >

        <div className="heroImg1-container w-full">
          <img id="heroImg1" src={hero1} alt="." />
        </div>
        <div className="heroMid">
          <div className="offerMatchMeet d-flex flex-column align-items-center justify-content-center">
            {/* <div className='heroText1'>OFFER</div> */}
            <span className="float-box2">
              <p className="float2">DISCOVER NEW RESTAURANTS</p>
            </span>
            {/* <div className='heroText3'>MATCH</div> */}
            <span className="float-box">
              <p className="float">BUY & SHARE EXPERIENCES</p>
            </span>

            {/* <div className='heroText2'>MEET</div> */}
            <span className="float-box3">
              <p className="float3">MAKE NEW FRIENDS</p>
            </span>
          </div>
          <div className="heroText flex text-center">
            <p className=" font-semibold   ">
              Discover Flavors, Engage in Conversations, Create Bonds
            </p>
          </div>
          {/* <div className="explore d-flex gap-2 justify-content-around align-items-center">
            <p
              id="exploreText"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#7B7B7B",
                marginTop: "10px",
                padding: "4px 10px",
              }}
            >
              New way to explore and connect with people
            </p>
            <a href="">
              <button className="Btn2">Get the app NOW</button>
            </a>
          </div> */}
          <div className="explore">
            {/* <div className="exploreText" style={{ textAlign: "center" }}>
              <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                First 500 Users get free Coffee(Register Now)
              </p>
              <p style={{ fontSize: "14px" }}>
                Also refer your friends to get Premium Features for free
              </p>
              <p></p>
            </div> */}

            <div className=" w-[200px] overflow-hidden floating-Bar">

            <div
              style={{
                // width: "40%",
                marginTop: "10vh",
                animation: "scroll 15s linear infinite",
                whiteSpace: "nowrap",
                
            
              }}
              className=" d-flex align-items-center justify-content-center"
            >
              <img
                src={eldorado}
                height={100}
                width={120}
                alt=""
                className=" z-50 rounded-full mx-10"
              />
              <img
                src={bonkey}
                height={100}
                width={120}
                alt=""
                className=" rounded-full mx-10 "
              />
              <img
                src={coffeeCartel}
                height={100}
                width={100}
                alt=""
                className=" rounded-full mx-10"
              />
              <img
                src={drunkenMonkey}
                height={100}
                width={120}
                alt=""
                className="mx-10"
              />
              <img
                src={elixir}
                height={100}
                width={120}
                alt=""
                className=" rounded-full mx-10"
              />
              <img
                src={theFarm}
                height={100}
                width={120}
                alt=""
                className=" rounded-full mx-10"
              />
            </div>
            </div>

            <style>{`
                      @keyframes scroll {
                        0% {
                          transform: translateX(100%);
                        }
                        100% {
                          transform: translateX(-100%);
                        }
                      }
                      .scroller {
                        width: 5%;
                      }
                    `}</style>
          </div>
         
        </div>
        <div className="w-full">
          <img className="w-full" id="heroImg2" src={hero2} alt="." />
        </div>
      </div>
      <div className="buyAndOffer d-flex gap-10 justify-content-between align-items-center">
        <div className="buyAndOfferPhone d-flex justify-content-start align-items-center ">
          <div className="Shadow d-flex align-items-center justify-content-end w-full">
            <div className="Text ">
              <p className="Text1">BUY AND SHARE EXPERIENCES</p>
              <p className="Text2">BUY AND SHARE EXPERIENCES</p>
              <p className="Text3">BUY AND SHARE EXPERIENCES</p>
              <p className="Text4">BUY AND SHARE EXPERIENCES</p>
              <p className="Text5">BUY AND SHARE EXPERIENCES</p>
            </div>
          </div>
          <div className="mockup1Parent d-flex align-items-center w-full">
            <img className="mockup1" src={mockup1} alt="." />
          </div>
        </div>
        <div className="wrapper-container">
          <section className="wrapper">
            <div className="sentence">
              Espresso your thoughts over a
              <div className="slidingVertical">
                <span>Coffee.</span>
                <span>Beer.</span>
                <span>Movie.</span>
                <span>Trip.</span>
              </div>
            </div>
          </section>
          <img className="hero3" src={hero3} alt="." />
          {/* img-fluid */}
        </div>
      </div>

      <div className="Meet d-flex align-items-center justify-content-between">
        <div>
          <img className="hero5" src={hero5} alt="." />
        </div>
        <div className="linearBackground1"></div>
        <div className="mockup3Parent d-flex flex-column justify-content-center align-items-center">
          <img className="mockup3" src={mockup3} alt="." />
        </div>
        <div className="linearBackground2"></div>
        <div>
          <img className="hero6" src={hero6} alt="." />
        </div>
      </div>

      <div
        className="Restaurant d-flex justify-content-around align-items-center"
        style={{
          backgroundImage: `linear-gradient(90deg, #000000 -5.96%, rgba(0, 0, 0, 0.63) 52.83%, rgba(217, 217, 217, 0) 106.25%),url(${restaurant}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          marginTop: "100px",
          marginBottom: "100px",
          position: "relative",
        }}
      >
        <div className="mockup4Parent">
          <img className="mockup4" src={mockup4} alt="." />
        </div>
        <div className="registerRestaurantWrapper py-5">
          <p className="registerRestaurant">
            Register your restaurant/cafe
            <br />
            on UMLA
          </p>
          <p className="getCustomers">and get more customers!</p>

          <button
            onClick={handleClick}
            style={{
              color: "#6D12FF",
              fontWeight: "600",
              fontSize: "15px",
              background: "white",
              borderRadius: "10px",
              border: "none",
              padding: "12px 60px",
              marginTop: "20px",
            }}
          >
            Register Your Restaurant
          </button>
        </div>
      </div>
      <div className='blogWrapper d-flex flex-column justify-content-center align-items-center'>
				<p
					style={{
						fontSize: '35px',
						fontWeight: '700',
						color: '#232233',
					}}
				>
					OUR RECENT BLOG
				</p>
				<p
					style={{
						textAlign: 'center',
						fontSize: '14px',
						color: '#6C6C72',
						marginBottom: '50px',
						width: '700px',
					}}
					className='blogTextDesc'
				>
					Explore insightful articles on meaningful connections,
					professional networking, and shared experiences. Get
					inspired with stories, tips, and advice for fostering
					authentic relationships in the digital age. Discover the
					power of genuine connections through UMLA's engaging blog.
					Ignite your curiosity and unlock the potential of authentic
					relationships today.
				</p>
				<div className='d-flex flex-wrap gap-3 justify-content-center align-items-center'>
					<div className='Card card'>
						<img
							className='card-img-top p-1'
							src={connectread}
							alt='.'
						/>
						<div className='card-body'>
							<h6
								style={{
									fontWeight: '600',
									color: '#232233',
									textTransform: 'uppercase',
									marginTop: '15px',
								}}
							>
								Connect, Explore, Share Adventures
							</h6>
							<p
								style={{
									fontSize: '12px',
									color: '#6C6C72',
									marginTop: '30px',
								}}
							>
								Looking for the perfect travel companion or movie buddy? Umla makes it easy to connect with like-minded individuals who share your passion for adventure and entertainment. Whether you're looking for someone to explore a new city with, catch the latest blockbuster film, or simply share travel tips and movie recommendations, Umla has the tools and community to make it happen.
							</p>
							<a
								style={{
									color: '#6D12FF',
									fontSize: '15px',
									fontWeight: '600',
									marginTop: '50px',
								}}
								href='/blog/2'
							>
								READ MORE
							</a>
						</div>
					</div>
					<div className='Card card'>
						<img
							className='card-img-top p-1'
							src={coffee}
							alt='.'
						/>
						<div className='card-body'>
							<h6
								style={{
									fontWeight: '600',
									color: '#232233',
									textTransform: 'uppercase',
									marginTop: '15px',
								}}
							>Making Connections, One Coffee Date at a Time with Umla
							</h6>
							<p
								style={{
									fontSize: '12px',
									color: '#6C6C72',
									marginTop: '30px',
								}}
							>
							At Umla, we believe that the best connections are made over a cup of coffee. That's why we've created a platform that brings people together, one coffee date at a time. Whether you're looking to expand your professional network, meet new friends, or even find your soulmate, Umla is here to help you make meaningful connections over a shared love for coffee.
							</p>
							<a
								style={{
									color: '#6D12FF',
									fontSize: '15px',
									fontWeight: '600',
									marginTop: '50px',
								}}
								href='/blog/1'
							>
								READ MORE
							</a>
						</div>
					</div>
					<div className='Card card'>
						<img
							className='card-img-top p-1'
							src={network}
							alt='.'
						/>
						<div className='card-body'>
							<h6
								style={{
									fontWeight: '600',
									color: '#232233',
									textTransform: 'uppercase',
									marginTop: '15px',
								}}
							>
								Your Gateway to Meaningful Networking
							</h6>
							<p
								style={{
									fontSize: '12px',
									color: '#6C6C72',
									marginTop: '30px',
								}}
							>
								Welcome to Umla – your gateway to a world of meaningful connections, enriching collaborations, and endless opportunities. Whether you're a seasoned professional, an aspiring entrepreneur, or simply someone who loves to connect with like-minded individuals, Umla is here to help you forge deep and lasting relationships that transcend boundaries.
							</p>
							<a
								style={{
									color: '#6D12FF',
									fontSize: '15px',
									fontWeight: '600',
									marginTop: '50px',
								}}
								href='/blog/3'
							>
								READ MORE
							</a>
						</div>
					</div>
				</div>
				<a href='/blog'>
					<button className='Btn'>View More</button>
				</a>
			</div>
      <div className="downloadAppWrapper d-flex justify-content-around align-items-center">
        <div className="downloadApp p-5">
          <p
            className="downloadAppNow"
            style={{
              fontSize: "30px",
              color: "white",
              fontWeight: "700",
            }}
          >
            DOWNLOAD APP NOW
          </p>
          <p
            className="downloadAppText"
            style={{
              fontSize: "14px",
              color: "white",
              marginTop: "15px",
              width: "350px",
            }}
          >
            Experience the power of connecting with like-minded individuals
            right at your fingertips. Discover a world of shared experiences,
            exciting meet-ups, and endless possibilities. Download the UMLA app
            today and unlock a universe of connections waiting to be explored.
          </p>
          <div className="downloadButtons" style={{ marginTop: "35px" }}>
            <a href="https://play.google.com/store/apps/details?id=com.umlaa.app">
            <img
              className="DownloadButton1"
              style={{ width: "180px", height: "55px" }}
              src={download1}
              alt="."
            />
            </a>
          </div>
        </div>
        <div className="mockup5Parent">
          <img className="mockup5" src={mockup5} alt="." />
        </div>
      </div>

      {isRegister && (
        <div
          className="overlay-container"
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: "999", // Higher z-index to appear on top
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FullPage handleCloseRegister={handleCloseRegister} />
        </div>
      )}
      </div>
    </>
  );
}

export default Home;
