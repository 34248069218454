import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {onBoardRequestReducer} from "./reducers/partnerReducer"

const reducer = combineReducers({
  onBoardRequest: onBoardRequestReducer
})

const middleware = [thunk]

const store = configureStore({
  reducer,
  middleware,
  preloadedState:{},
})
export default store;