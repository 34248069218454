import React from 'react';
import '../css/blogs.css';
import blogbackground from '../images/blogbackground.jpg';
import blog1 from '../images/blog1.jpg';
import blog2 from '../images/blog2.jpg';
import blog3 from '../images/blog3.jpg';
import connect from '../images/connect.png'
import coffeedate from '../images/coffeedate.png'
import networking from '../images/networking.png'
import match from '../images/findmatch.png'
import explore from '../images/explore.png'
import datesafer from '../images/datesafer.png'
import hub from '../images/hub.png'
import seewho from '../images/seewho.png'

import BlogCard from './BlogCard';

function Blogs() {
    const dummy = [
        {title:"Connect, Explore, Share Adventures" ,image:connect , content:
        "Looking for the perfect travel companion or movie buddy?", link: "/blog/2"},
        {title:"Making Connections, One Coffee Date at a Time with Umla",image:coffeedate , content:"At Umla, we believe that the best connections are made over a cup of coffee.", link: "/blog/1"},
        {title:"Your Gateway to Meaningful Networking" ,image:networking , content:"Welcome to Umla – your gateway to a world of meaningful connections,", link: "/blog/3"},
        {title:"Find Your Perfect Match, Anytime" ,image:match , content:"Are you tired of endless swiping and fruitless searches for your ideal match?", link: "/blog/4"},
        {title:"Explore Your Passion with Umla: Connect, Collaborate, Create" ,image:explore , content:"Umla is not just another networking platform",link: "/blog/5"},
        {title:"Date Safer with Umla" ,image:datesafer, content:"At Umla, we prioritize your safety and security above all else, especially ",link: "/blog/6"},
        {title:"Your Personalized Networking Hub" ,image:hub , content:"Are you ready to take your networking experience to the next level? ",link: "/blog/7"},
        {title:"See Who Likes You" ,image:seewho , content:"Ever wondered who's interested in connecting with you on Umla?",link: "/blog/8"},
       
    ]
    return (
        <>
            <div className='d-flex justify-content-center align-items-end' style={{ marginTop: "68px", backgroundImage: `url(${blogbackground})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition:"center",height: "91vh" }}>
                <div style={{ background: "white", fontSize: "40px", fontWeight: "800", color: "#6D12FF", borderRadius: "20px", padding: "10px 30px", width: "900px", textAlign: "center", marginBottom: "110px" }}>OUR BLOGS</div>
            </div>
            <div className='mt-5 d-flex flex-column justify-content-center align-items-center'>
                <p style={{fontSize:"30px",fontWeight:"600"}}>All Articles</p>
                <p className='articlesDesc' >
                Ready to expand your horizons and connect with like-minded individuals across industries and interests? Join [umla networking app] – the ultimate destination for professionals, entrepreneurs, travelers, movie buffs, outdoor enthusiasts, and more. Sign up today and embark on a journey of meaningful connections, shared experiences, and endless possibilities!

                </p>
            </div>
            <div className='p-5 mt-1 d-flex flex-wrap gap-4 justify-content-center align-items-center'>
                {dummy.map((data, i)=>(
                    <BlogCard key={i}
                       title={data.title}
                       image={data.image}
                       content={data.content}
                       link={data.link}

                    />
                ))}
            </div>
        </>
    )
}

export default Blogs