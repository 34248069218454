import { useState } from "react";
import Sidebar from "./Sidebar";
import Body from "./Body";

const FullPage = ({ handleCloseRegister }) => {
  const [bodyStep, setBodyStep] = useState(1);

  const onchange = (data) => {
    setBodyStep(data);
  };

  return (
    <div className="flex flex-col md:flex-row p-2 bg-white rounded-3xl my-10 relative w-auto">
      <button
        className=" absolute top-2 right-3 p-2 cursor-pointer"
        onClick={handleCloseRegister}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          className="bi bi-x"
          viewBox="0 0 14 14"
        >
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </button>
      <div className="md:w-4/12 px-10">
        <Sidebar bodyStep={bodyStep} />
      </div>

      <div className="md:w-8/12 mx-10 ">
        <Body bodyStep={bodyStep} onchange={onchange} />
      </div>
    </div>
  );
};

export default FullPage;
