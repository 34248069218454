import React from "react";
import { Link } from "react-router-dom";
import logonavbar from "../images/logonavbar.svg";
import togglerIcon from "../images/togglerIcon.svg";
import "../css/navbar.css";

function Navbar() {
  return (
    <div style={{ background: "#FFFFFF" }}>
      <nav
        className="navbar fixed-top navbar-expand-lg "
        style={{ marginTop: "-8px" }}
      >
        <div className="navbarWrap container-fluid py-2">
          <div>
            <Link to="/">
              <img className="logonavbar" src={logonavbar} alt="." />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            style={{ border: "none" }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span>
              <img src={togglerIcon} alt="." />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-3 mb-lg-0">
              <li className="nav-item">
                <Link className="Li nav-link" to="/">
                  HOME
                </Link>
              </li>
              {/* <li className=" nav-item">
                        <Link className="Li nav-link" to="/about">ABOUT</Link>
                    </li> */}
              <li className=" nav-item">
                <Link className="Li nav-link" to="/privacy">
                  PRIVACY POLICY
                </Link>
              </li>
              <li className=" nav-item">
                <Link className="Li nav-link" to="/restaurants">
                  RESTAURANTS
                </Link>
              </li>
              <li className=" nav-item">
                <Link className="Li nav-link" to="/blog">
                  BLOG
                </Link>
              </li>
              <li className=" nav-item">
                <Link className="Li nav-link" to="/contact">
                  CONTACT US
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://play.google.com/store/apps/details?id=com.umlaa.app"
                >
                  <button className="Btn1">DOWNLOAD</button>
                </a>
              </li>
            </ul>
            <ul className="d-flex"></ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
