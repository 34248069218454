import React from 'react'
import '../css/blog.css';
import blog from '../images/blog.jpg';
import blogimg from '../images/blogimg.jpg';
import author from '../images/author.svg';
import search from '../images/search.png';
import dateread from '../images/dateread.png';

function Blog6() {
  return (
    <>
    <div style={{ marginTop: "68px", marginRight: "0", marginLeft: '0' }}>
        <img style={{ width: "98.9vw" }} src={blog} alt="." />
    </div>
    <div className='blogPageWrapper d-flex flex-wrap align-items-center justify-content-between'>
        <div className='blogPage' >
            <p style={{ fontSize: "24px", fontWeight: "700" }}>Date Safer with Umla
</p>
            <img className='blogImage' src={dateread} alt="." />
            <p className='blogText'>At Umla, we prioritize your safety and security above all else, especially when it comes to arranging face-to-face meetings. Our platform is designed to provide you with the tools and resources you need to date safer and with peace of mind, ensuring that your interactions are secure and enjoyable every step of the way.With Umla, you can connect with potential matches face-to-face with confidence, knowing that your safety is our top priority. Whether you're meeting for a casual coffee date or planning a more elaborate outing, Umla provides the tools and support you need to ensure a safe and enjoyable experience for both parties involved. Download Umla today and take the first step towards dating safer and with confidence!</p>
        </div>
        <div className='d-flex gap-3 flex-column align-items-start justify-content-start'>
            <div className='author d-flex gap-3 align-items-center justify-content-start'>
                <img style={{ height: "80px", width: "70px" }} src={author} alt="." />
                <div style={{ marginTop: "15px" }}>
                    <p style={{ fontSize: "15px", fontWeight: "600",marginTop:"-10px" }}>Written By</p>
                    <p style={{ color: "#5E5E5E", fontWeight: "600", fontSize: "15px" ,marginTop:"10px"}}>Sneha Sharma</p>
                    <p style={{ color: "#676767", fontSize: "10px",marginTop:"8px" }}>Date:- 13-March-2024</p>
                </div>
            </div>
            <div className='suggestions'>
                <p style={{ fontSize: "14px", fontWeight: "600",marginLeft:"14px" }}>You May Also Like</p>
                <div className='d-flex flex-column gap-1' style={{ lineHeight: "15px", width: "200px", marginLeft: "15px" }}>
                    <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/8">See Who Likes You</a> <br />
                    <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/4">Find Your Perfect Match</a><br />
                    <a style={{ fontSize: "12px", color: "#5F5F5F", fontWeight: "600", textDecoration: "underline" }} href="/blog/5">Explore Your Passion with Umla</a><br />
                </div>
            </div>
            {/* <div className='search pb-4 d-flex flex-column'>
                <img src={search} alt="." />
                <div>
                   <p style={{fontWeight:"600" ,fontSize:"17px",padding:"7px",textAlign:"center"}}>Choose Type ?</p>
                   <div className='d-flex gap-2 flex-column align-items-center justify-content-center'>
                   <select style={{width:"260px",borderRadius:"8px",border:"none",padding:"4px"}} name="" id=""></select>
                   <button style={{color:"white",background:"#6D12FF",width:"150px",fontSize:"14px",fontWeight:"500",border:"none",borderRadius:"7px",padding:"7px"}}>Search</button>
                   </div>
                </div>
            </div> */}
        </div>
    </div>
</>
  )
}

export default Blog6;